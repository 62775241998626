import { createAuth0Client } from '@auth0/auth0-spa-js';

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;

export const auth0Config = {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin,
  },
};

/**
 * Configure Auth0 client.
 * @see https://auth0.com/docs/libraries/auth0-spa-js
 */
export const auth0 = await createAuth0Client(auth0Config);
